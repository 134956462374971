import React, { useState, useEffect, useContext } from "react";
import "./filter.css";
import {
  Autocomplete, Button, Icon, Box, Chip
} from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import TextField from '@mui/material/TextField';
import { getCustomers } from './../../firebase/customerApi';
import filterData from "./filterData";
import { UserContext, FiltersContext, OrdersContext, CustomersContext } from "../../context";
import moment from "moment";
import { useParams } from "react-router-dom";

function Filter() {
  const [user, setUser] = useContext(UserContext);
  const [orders, setOrders] = useContext(OrdersContext);
  const [customers, setCustomers] = useContext(CustomersContext);
  const [selectedFilters, setSelectedFilters] = useContext(FiltersContext);
  const { id } = useParams();

  console.log(selectedFilters.customer)

  useEffect(() => {
    getCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomerList = async () => {
    if (!(customers && customers.length > 0)) {
      const customerList = await getCustomers();
      setCustomers(customerList.map(customer => { return { uid: customer.uid, label: customer.firstName + " " + customer.lastName } }));
    }
  };

  const handleClick = (e, type) => {
    setOrders({ ...orders, data: [], lastDoc: null });
    setSelectedFilters({
      ...selectedFilters,
      [type]: e.target.checked ? (selectedFilters[type] ? [...selectedFilters[type], e.target.name] : [e.target.name]) : (selectedFilters[type] ? selectedFilters[type].filter((item) => item !== e.target.name) : []),
    });
  };

  const handleChange = (e, type) => {
    setOrders({ ...orders, data: [], lastDoc: null });
    setSelectedFilters({
      ...selectedFilters,
      [type]: e
    });
  };

  return (
    <div className="filter">
      <div className="filter-clear">
        <Button variant="outlined" onClick={() => {
          setOrders({ ...orders, data: [], lastDoc: null });
          setSelectedFilters({ date: moment(), customer: [] });
        }}><Icon>delete</Icon>Filtreyi Temizle</Button>
      </div>
      {user?.role === "admin" && !id && <Accordion>
        <Accordion.Item>
          <Accordion.Header className="accordion-header">
            MÜŞTERİ
          </Accordion.Header>
          <Accordion.Body className="accordion d-flex flex-column">
            <Autocomplete
              id="combo-box-demo"
              options={customers}
              value={selectedFilters.customer}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Müşteri Seçiniz" />
              )}
              onChange={(e, val) => {
                handleChange(val, "customer");
              }}
              multiple
            />
            <Box marginTop="15px" display="flex" justifyContent="end" width="100%" >
              <Chip label="Müşterisi olmayan siparişleri göster" 
              variant={selectedFilters.nocustomer ? "filled" : "outlined"} 
              onClick={() => {
                setOrders({ ...orders, data: [], lastDoc: null });
                setSelectedFilters({
                  ...selectedFilters,
                  customer: [],
                  nocustomer: !selectedFilters.nocustomer,
                });
              }} />
            </Box>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>}
      <Accordion>
        <Accordion.Item>
          <Accordion.Header className="accordion-header">
            TARİH
          </Accordion.Header>
          <Accordion.Body className="accordion d-flex flex-column">
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={selectedFilters.date}
              onChange={(newValue) => {
                handleChange(newValue, "date");
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {(selectedFilters.customer.length === 1 || id || selectedFilters.nocustomer) && selectedFilters.date && <Box display="flex" justifyContent="end" width="100%" >
              <Chip label="Tarihi Temizle" variant="outlined" onClick={() => {
                setOrders({ ...orders, data: [], lastDoc: null });
                setSelectedFilters({
                  ...selectedFilters,
                  date: null,
                  state: ["false"]
                });
              }} />
            </Box>}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {filterData.map((filter, index) => {
        return (
          <Accordion key={index}>
            <Accordion.Item eventKey={filter.id}>
              <Accordion.Header className="accordion-header">
                {filter.title}
              </Accordion.Header>
              <Accordion.Body className="accordion d-flex flex-column">
                {filter.items.map((item, index) => (
                  <Form.Check
                    type="checkbox"
                    checked={selectedFilters[filter.name] ? selectedFilters[filter.name].includes(item.value) : false}
                    key={index}
                    name={item.value}
                    label={item.name}
                    onClick={(e) => {
                      if (!(selectedFilters.date === null && filter.name === "state"))
                        handleClick(e, filter.name);
                    }}
                  />
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}

export default Filter;
