import React from 'react';
import {
  Outlet
} from "react-router-dom";
import Header from '../Header'
import Footer from '../Footer'
import Unauthorized from "../../components/Layout/Unauthorized";

function Layout({isAllowed, isPublic}) {
  
  return (
    <>
    <Header isPublic={isPublic}/>
    {isPublic || isAllowed ? <Outlet /> : <Unauthorized />}
    <Footer />
  </>
  )
}

export default Layout