import React from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


function CustomerDetail({customer, setCustomer}) {

    const changeCustomerValue = (name, value) =>{
        setCustomer(prevState => {
        return {
          ...prevState,
          [name]: value
        }
        })
    }

  return (
    <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="İsim"
                  autoFocus
                  value={customer.firstName}
                  onChange={e => {
                    changeCustomerValue("firstName",e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Soyisim"
                  name="lastName"
                  autoComplete="family-name"
                  value={customer.lastName}
                  onChange={e => {
                    changeCustomerValue("lastName",e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="E-Posta Adresi"
                  name="email"
                  autoComplete="email"
                  value={customer.email}
                  onChange={e => {
                    changeCustomerValue("email",e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Şifre"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={customer.password}
                  onChange={e => {
                    changeCustomerValue("password",e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
  )
}

export default CustomerDetail