import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { get, save, update, remove } from "../../firebase/orderApi";
import { uploadImage } from "../../firebase/fileApi";
import { getCustomers } from "./../../firebase/customerApi";
import { GlassMagnifier } from "react-image-magnifiers";
import {
  Card,
  CardActionArea,
  CardMedia,
  TextField,
  Autocomplete,
  Grid,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  ButtonGroup,
  Box,
  Button as ButtonMui
} from "@mui/material";
import { LinearProgressWithLabel, LeavingNotice } from "../../components/common";
import "./productDetails.css";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import upload from "../../assets/images/upload.jpg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { OrdersContext, FiltersContext, UserContext, CustomersContext } from "../../context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Rotate90Degree from "@mui/icons-material/Rotate90DegreesCcwOutlined";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import filterData from "../../components/Filter/filterData";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import moment from 'moment';

function ProductDetails() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [customers, setCustomers] = useContext(CustomersContext);
  const [loading, setLoading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [orders, setOrders] = useContext(OrdersContext);
  const [uploadPopupVisibility, setUploadPopupVisibility] = useState(false);
  const [file, setFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [order, setOrder] = useState({
    color: "",
    description: "",
    content: "",
    customerId: "",
    customerName: "",
    image: null,
    date: new Date().toDateString(),
    urgent: "",
    state: "",
  });
  const [leavingState, setLeavingState] = useState(false);
  const index = orders?.data.findIndex((x) => x.id === id);



  useEffect(() => {

    if (user.role === "admin" && id !== "new" && orders.data.length > 0)
      window.addEventListener("keydown", keydownHandler);
    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    getCustomerList();
    getOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  useEffect(() => {
    if (percent === 100) {
      setPercent(0);
      setUploadPopupVisibility(false);
    }

  }, [percent]);

  function keydownHandler({ key }) {
    if (key === "ArrowRight") {
      setOrderDetail("next");
    } else if (key === "ArrowLeft") {
      setOrderDetail("prev");
    } else if (key === "AltGraph") {
      updateOrder();
    }
  }

  const getCustomerList = async () => {
    if (!(customers && customers.length > 0)) {
      const customerList = await getCustomers();
      setCustomers(
        customerList.map((customer) => {
          return {
            uid: customer.uid,
            label: customer.firstName + " " + customer.lastName,
          };
        })
      );
    }
  };

  const getOrderDetail = async () => {

    if (id && id !== "new") {
      if (orders.data && orders.data.length > 0) {
        const order = orders.data.find((x) => x.id === id);
        setOrder(order);
      }
      else {
        const order = await get(id);
        setOrder(order);
      }
    }
    setLeavingState(false);
    setLoading(false);
  };

  const setOrderDetail = (direction) => {
    setLoading(true);
    onChangeOrder("image", "");
    if (direction === "next" && index < orders.data.length - 1) {
      const nextOrder = orders.data[index + 1];
      navigate(`/product-details/${nextOrder.id}`);
    } else if (direction === "prev" && index > 0) {
      const prevOrder = orders.data[index - 1];
      navigate(`/product-details/${prevOrder.id}`);
    } else {
      navigate(`/products`);
    }
    setLoading(false);
  };

  const addOrder = async () => {
    try {
      if (!order.image) {
        toast.error("Lütfen önce görseli yükleyin!");
        return;
      }
      await save({ ...order, state: false }).catch((e) => {
        toast.error("Sipariş oluşturulurken hata oluştu: ", e.message);
      });
      setLeavingState(false);
      setOrders({ ...orders, data: [...orders.data, { ...order, state: false }] });
      toast.success("Sipariş başarıyla oluşturuldu.");
      navigate(`/products`);
    } catch (e) {
      toast.error("Sipariş oluşturulurken hata oluştu: ", e);
    }
  };

  const updateOrder = async () => {
    await update(id, order).then(() => {
      setLeavingState(false);
      setOrders({ ...orders, data: orders.data.map(x => x.id === id ? order : x) });
      if (!(orders.data && orders.data.length > 0)) {
        navigate(`/products`);
      }
      toast.success("Sipariş başırıyla güncellendi.");
    }).catch(e => {
      toast.error("Sipariş güncellenirken hata oluştu: ", e);
    });
  };

  const removeOrder = async () => {
    await remove(id).then(() => {
      setLeavingState(false);
      if (!(orders.data && orders.data.length > 0)) {
        navigate(`/products`);
      } else {
        setOrders({ ...orders, data: orders.data.filter(x => x.id !== id) });
        if (index < orders.data.length - 1) {
          const nextOrder = orders.data[index + 1];
          navigate(`/product-details/${nextOrder.id}`);
        }
        else {
          navigate(`/products`);
        }
      }
      handleClose();
      toast.success("Sipariş başırıyla silindi.");
    }).catch(e => {
      toast.error("Sipariş silinirken hata oluştu: ", e);
    });
  };

  const uploadFile = (newFile) => {
    if (!file && !newFile) {
      toast.error("Lütfen önce görseli yükleyin!");
      setLoading(false);
    }
    uploadImage((file || newFile), setPercent, setUrl);
  };

  const setUrl = (val) => {
    onChangeOrder("image", val);
    setLoading(false);
  };

  const onChangeOrder = (name, value) => {
    setOrder((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setLeavingState(true);
  };

  const uploadPopup = () => {
    setFile(null);
    setPercent(0);
    setUploadPopupVisibility(true);
  };

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const compressorImage = (file) => {
    if (!file) {
      return;
    }

    new Compressor(file, {
      // Compression Settings
      quality: 0.6,
      maxWidth: 1000,
      success(result) {
        const outFile = new File([result], file.name, { type: result.type });
        setFile(outFile);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const rotateImage = async () => {
    setLoading(true);
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = order.image;

    img.onload = function () {
      canvas.width = img.height;
      canvas.height = img.width;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      canvas.toBlob(function (blob) {
        const newFile = new File([blob], `${moment().format("YYYYMMDDHHmm")}.jpg`, { type: "image/jpeg" });
        uploadFile(newFile);
      });
    }

  };

  const imageRender = () => {

    if (order.image === null) {
      return <CardMedia
        component="img"
        width="450"
        height="400"
        image={upload}
        alt="upload"
        style={{ objectFit: 'contain' }}
      />
    }
    else if (order.image === "") {
      return <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e7ebf1b5' }}
      >
        <CircularProgress color="inherit" />
      </div>
    }
    else
      return <Box class="MuiCardMedia-root MuiCardMedia-media glass-magnifier-box">
        <GlassMagnifier
          imageSrc={order.image}
          imageAlt="img_preview"
          magnifierSize="30%"
        />
      </Box>

  }
  return (
    <Box className="body-container">
      <LeavingNotice
        leavingState={leavingState}
        setLeavingState={setLeavingState}
        onSaveAndLeave={() => {
          if (id === "new") {
            addOrder();
          } else {
            updateOrder();
          }
        }}
      />
      {id === "new" && (
        <div className="w-100 d-flex">
          <ButtonGroup
            className="ml-auto"
            variant="outlined"
            aria-label="outlined button group"
          >
            <ButtonMui
              onClick={() => {
                navigate(`/product-details/multi`);
              }}
            >
              Toplu Sipariş
            </ButtonMui>
            <ButtonMui disabled>Tekli Sipariş</ButtonMui>
          </ButtonGroup>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {id !== "new" && orders.data && orders.data.length > 0 && (
        <IconButton
          onClick={() => setOrderDetail("prev")}
          style={{ position: "absolute", top: "40vh", left: "5%", backgroundColor: '#e7ebf1b5' }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {id !== "new" && orders.data && orders.data.length > 0 && (
        <IconButton
          onClick={() => setOrderDetail("next")}
          style={{ position: "absolute", top: "40vh", right: "5%", backgroundColor: '#e7ebf1b5' }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
      <Grid container>
        <Grid item sm={6}>
          <Card>
            <CardActionArea onClick={uploadPopup}>
              {order.image !== null && order.image !== "" && <>
                <Box className="cursor-pointer" position="absolute" zIndex={1000} boxShadow="1px 0px 20px 5px #9c9b9b" marginTop="10px" marginLeft="10px" borderRadius="5px"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(order.image, "_blank");
                  }}>
                  <FullscreenIcon />
                </Box>
                <Box className="cursor-pointer" position="absolute" zIndex={1000} boxShadow="1px 0px 20px 5px #9c9b9b" marginTop="10px" marginLeft="40px" borderRadius="5px"
                  onClick={(e) => {
                    e.stopPropagation();
                    rotateImage();
                  }}>
                  <Rotate90Degree />
                </Box>
              </>}
              {imageRender()}
            </CardActionArea>
            <Dialog
              onClose={() => setUploadPopupVisibility(false)}
              open={uploadPopupVisibility}
            >
              <DialogTitle>Resim Yükleme</DialogTitle>
              <div style={{ padding: "20px" }}>
                <input
                  type="file"
                  onChange={(event) => {
                    compressorImage(event.target.files[0]);
                    // setFile(event.target.files[0]);
                  }}
                  accept="/image/*"
                />
                <Button variant="success" onClick={uploadFile}>Yükle</Button>
                <LinearProgressWithLabel value={percent} />
                <Button variant="secondary" onClick={() => setUploadPopupVisibility(false)}>
                  Kapat
                </Button>
              </div>
            </Dialog>
          </Card>
        </Grid>

        <Grid item sm={6}>
          {orders?.data?.length > 1 && <Box textAlign="end" fontWeight={600} fontSize="larger" fontFamily="monospace">
            {(index + 1) + "/" + orders?.data?.length}
          </Box>}
          <Form>
            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label column sm="3">
                <h6>Müşteri Adı:</h6>
              </Form.Label>
              <Col sm="5">
                <Autocomplete
                  id="combo-box-demo"
                  options={customers}
                  value={order.customerName}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={order.customerName ? "Müşteri" : "Müşteri Seçiniz"}
                    />
                  )}
                  onChange={(e, val) => {
                    setOrder((prevState) => {
                      return {
                        ...prevState,
                        customerId: val ? val.uid : '',
                        customerName: val ? val.label : '',
                      };
                    });
                  }}
                  readOnly={user.role !== "admin"}
                />
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label column sm="3">
                <h6>Açıklama:</h6>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Açıklama giriniz."
                  value={order.description}
                  onChange={(e) => {
                    onChangeOrder("description", e.target.value);
                  }}
                  readOnly={user.role !== "admin"}
                  style={{ resize: "none", backgroundColor: "unset" }}
                />
              </Col>
            </Form.Group> */}

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Ayar:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="18"
                  name="radio-content-group"
                  value={order.content}
                  onChange={(e) => {
                    if (user.role === "admin")
                      onChangeOrder("content", e.target.value);
                  }}
                >
                  {filterData
                    .find((x) => x.name === "content")
                    ?.items.map((x) => {
                      return (
                        <FormControlLabel
                          value={x.value}
                          control={<Radio />}
                          label={x.name}
                        />
                      );
                    })}
                </RadioGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Renk:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="gold"
                  name="radio-color-group"
                  value={order.color}
                  onChange={(e) => {
                    if (user.role === "admin")
                      onChangeOrder("color", e.target.value);
                  }}
                >
                  {filterData
                    .find((x) => x.name === "color")
                    ?.items.map((x) => {
                      return (
                        <FormControlLabel
                          value={x.value}
                          control={<Radio />}
                          label={x.name}
                        />
                      );
                    })}
                </RadioGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Tarih:</h6>
              </Form.Label>

              <Col sm="9">
                <DatePicker
                  value={new Date(order.date)}
                  onChange={(e) => {
                    onChangeOrder("date", new Date(e).toDateString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  readOnly={user.role !== "admin"}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label
                id="demo-form-control-label-placement"
                column
                sm="3"
              >
                <h6> Aciliyet:</h6>
              </Form.Label>

              <Col sm="9">
                <Switch
                  checked={order.urgent}
                  onChange={(e) => {
                    if (user.role === "admin")
                      onChangeOrder("urgent", e.target.checked);
                  }}
                />
              </Col>
            </Form.Group>

            {id !== "new" && (
              <Form.Group as={Row} className="m-3" controlId="formPlainText">
                <Form.Label
                  id="demo-form-control-label-placement"
                  column
                  sm="3"
                >
                  <h6> Tamamlanma Durumu:</h6>
                </Form.Label>

                <Col sm="9">
                  <Switch
                    checked={order.state}
                    onChange={(e) => {
                      if (user.role === "admin")
                        onChangeOrder("state", e.target.checked);
                    }}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group className="buttons">
              {user.role === "admin" ? (
                id === "new" ? (
                  <Button variant="primary" onClick={() => addOrder()}>
                    Kaydet
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => navigate("/products")}
                      style={{ marginRight: "10px" }}
                    >
                      Kapat
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => setOpenDeletePopup(true)}
                      style={{ marginRight: "10px" }}
                    >
                      Sil
                    </Button>
                    <Button variant="success" onClick={() => updateOrder()}>
                      Güncelle
                    </Button>
                  </>
                )
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => navigate("/products")}
                  style={{ marginRight: "10px" }}
                >
                  Kapat
                </Button>
              )}
            </Form.Group>
          </Form>
        </Grid>
      </Grid>
      <Dialog open={openDeletePopup} onClose={handleClose}>
        <DialogContent>
          <p>
            Seçilen siparişi silmek istediğinize emin misiniz? Bu geri
            alınamayan bir işlemdir.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button variant="danger" onClick={removeOrder}>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProductDetails;
