import React from 'react';
import {
    Grid, Button, ButtonGroup, Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tabs,
    Typography,
    Tab
} from "@mui/material";
import "./metrics.css"
import OrderMetric from './OrderMetric';
import CustomerMetric from './CustomerMetric';

function Metrics() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    return (
        <div className="metrics">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Müşteri Durumu" {...a11yProps(0)} />
                    <Tab label="30 Günlük Sipariş Durumu" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <CustomerMetric /> 
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <OrderMetric />
            </CustomTabPanel>
        </div>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`metric-tabpanel-${index}`}
        aria-labelledby={`metric-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ px: 10, py:5, display: 'flex', justifyContent: 'center' }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default Metrics;
