import React, { useState, useContext } from 'react';
import "./login.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/config"
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from "@mui/material/CircularProgress";
import { UserContext } from "../../context";
import { getCustomerById } from "./../../firebase/customerApi";
import { toast } from 'react-toastify';


function Login() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate()

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        debugger;
        const user = userCredential.user;
        getCurrentUser(user)
      })
      .catch((error) => {
        toast.error("Yanlış e-mail yada şifre!");
        setLoading(false);
      });

  };


  const getCurrentUser = async (user) => {

    await getCustomerById(user.uid).then((customerUser) => {
      if (!customerUser)
        return;
      localStorage.setItem("token", customerUser.role[0] + user.accessToken);
      localStorage.setItem("userId", user.uid);
      setUser({ ...user, ...customerUser });
      setLoading(true);
      toast.success("Giriş başarılı! Yönlendiriliyorsunuz...");
      setInterval(() => {
        window.location.href = "/products";
      }, 1000);

    }).finally(() => {
      setLoading(false);
    });

  }


  return (
    <div className='login-container'>
      <form className="form" onSubmit={handleLogin}>
        <TextField id="text" label="E-mail Adresi" name="email" onChange={e => setEmail(e.target.value)} /><br />
        <TextField id="text" label="Şifre" type="password" name="password" onChange={e => setPassword(e.target.value)} /><br />
        <button className='get-started-btn submit' type="submit" disabled={loading}>
          {loading && <CircularProgress color="inherit" size="1rem" style={{ marginRight: '2px' }} />}
          Giriş
        </button>
      </form>
    </div>
  )
}

export default Login

