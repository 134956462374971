import React, { useState, useContext, useEffect } from 'react'

import Card from 'react-bootstrap/Card';
import { Box, Icon, Checkbox, IconButton } from "@mui/material";
import './card.css';
import Button from 'react-bootstrap/Button';
import UncompletedIcon from "@mui/icons-material/HourglassBottom";
import CompletedIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReportIcon from "@mui/icons-material/Report";
import { GlassMagnifier } from "react-image-magnifiers";
import { useNavigate } from 'react-router-dom';
import filterData from '../../components/Filter/filterData';
import { UserContext } from "../../context";
import moment from 'moment';
import { toast } from 'react-toastify';
import { update } from "../../firebase/orderApi";
import 'moment/locale/tr';

export default function Cards({ orderDetail, selectedItems, setSelectedItems, viewMode }) {
  moment.locale('tr')
  const [user, setUser] = useContext(UserContext);
  const [order, setOrder] = useState({ ...orderDetail });
  let navigate = useNavigate();

  useEffect(() => {
    setOrder(orderDetail)
  }, [orderDetail]);


  const navigateDetailPage = () => {
    navigate(`/product-details/${order?.id}`);
  }

  const changeState = async (state) => {
    try {
      const newOrder = { ...order, state: !state };
      setOrder(newOrder);
      await update(order?.id, newOrder);
      if (state)
        toast.info("Sipariş hazırlanıyor durumuna alındı.");
      else
        toast.success("Sipariş tamamlandı durumuna alındı.");
    } catch (e) {
      toast.error("Düzenleme yapılırken hata alındı: ", e);
    }

  }

  const stateRender = () => {
    if (user?.role === "admin") {
      return <IconButton className='p-0' onClick={() => changeState(order?.state)}>{order?.state ? <CompletedIcon color='success' /> : <UncompletedIcon color='warning' />}</IconButton>
    } else {
      return order?.state ? <CompletedIcon color='success' /> : <UncompletedIcon color='warning' />
    }

  }

  const urgentRender = () => {
    return order?.urgent && <ReportIcon color='error' style={{ marginRight: '5px' }} />;
  }

  return (

    <Card className='mb-3' style={{ backgroundColor: selectedItems.includes(order?.id) ? '#fff6da' : 'white' }}>
      {user?.role === "admin" && <Box position="absolute" zIndex="100">
        <Checkbox checked={selectedItems.includes(order?.id)} onChange={(e) => {
          if (e.target.checked) {
            setSelectedItems([...selectedItems, order?.id])
          } else {
            setSelectedItems(selectedItems.filter(x => x !== order?.id))
          }
        }} sx={{ '& > .MuiSvgIcon-root': { backgroundColor: 'white', borderRadius: '5px' } }} />
      </Box>}
      <Box class={viewMode === "grid" ? "glass-magnifier-grid-card" : "glass-magnifier-single-card"}>
        {order?.image && <GlassMagnifier
          imageSrc={order?.image}
          imageAlt="img_preview"
          magnifierSize={viewMode === "grid" ? "30%" : "40%"}
        />}
      </Box>
      <Card.Body>
        {order?.customerName ? <Card.Title className="h6">{order?.customerName}</Card.Title> : <Card.Title className="h6" style={{ color: 'red', fontSize: 'smaller' }}>Müşteri Seçilmedi</Card.Title>}
        <Card.Text>
          <Box display="flex">
            <Box>
              <span style={{ fontSize: 'smaller', textTransform: 'capitalize' }}>
                {filterData.find(x => x.name === 'content')?.items.find(x => x.value === order?.content)?.name} - {filterData.find(x => x.name === 'color')?.items.find(x => x.value === order?.color)?.name}
              </span>
              <br />
              <span style={{ fontSize: 'smallest' }}>{moment(order?.date).format("Do MMM YYYY")}</span>
            </Box>
            <Box marginLeft="auto">
              {urgentRender()}
              {stateRender()}
            </Box>

          </Box>

        </Card.Text>
      </Card.Body>
      <Button onClick={() => navigateDetailPage()}>
        <Box display="flex" alignItems="center" justifyContent="center"><small >Detayları Gör </small><Icon style={{ marginTop: 0 }}>arrow_right</Icon></Box>
      </Button>
    </Card>



  )
}
