const data = [
    {
      id: "1",
      name: "content",
      title: "AYAR",
      items: [
        {
          name: "14 Ayar",
          value: "14"
        },
        {
          name: "18 Ayar",
          value: "18"
        },
        {
          name: "8 Ayar",
          value: "8"
        },
        {
          name: "Gümüş",
          value: "gmş"
        },
        {
          name: "22 Ayar",
          value: "22"
        },
        {
          name: "21 Ayar",
          value: "21"
        },
        {
          name: "10 Ayar",
          value: "10"
        },
        {
          name: "9 Ayar",
          value: "9"
        }
      ],
    },
    {
      id: "2",
      name: "color",
      title: "RENK",
      items: [
        {
          name: "Beyaz",
          value: "white"
        },
        {
          name: "Yeşil",
          value: "green"
        },
        {
          name: "Roz",
          value: "rose"
        },
        {
          name: "Diğer",
          value: "other"
        },
      ],
    },
    {
      id: "3",
      name: "urgent",
      title: "ACİLİYET DURUMU",
      items: [
        {
          name: "Acil Sipariş",
          value: "true"
        },
        {
          name: "Acil Olmayan Sipariş",
          value: "false"
        }
      ],
    },
    {
      id: "4",
      name: "state",
      title: "SİPARİŞ DURUMU",
      items: [
        {
          name: "Tamamlanmış",
          value: "true"
        },
        {
          name: "Tamamlanmamış",
          value: "false"
        }
      ],
    }
  ];

  export default data;