import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { saveMulti } from "../../firebase/orderApi";
import { uploadMultiImage } from "../../firebase/fileApi";
import { getCustomers } from "./../../firebase/customerApi";
import {
  Card,
  CardActionArea,
  CardMedia,
  TextField,
  Autocomplete,
  Grid,
  DialogTitle,
  Dialog,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  ButtonGroup,
  Button as ButtonMui,
  Icon
} from "@mui/material";
import { LinearProgressWithLabel } from "../../components/common";
import { GlassMagnifier } from "react-image-magnifiers";
import "./productDetails.css";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import upload from "../../assets/images/upload.jpg";
import filterData from "../../components/Filter/filterData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Compressor from "compressorjs";

function MultiProducts() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);
  const [percent, setPercent] = useState(0);
  const [multiPercent, setMultiPercent] = useState(0);
  const [order, setOrder] = useState({
    color: "",
    content: "",
    imageList: [],
  });

  useEffect(() => {
    if (uploadedFiles === files.length) {
      setLoading(false);
    }
  }, [uploadedFiles, files.length]);

  const saveOrder = async () => {
    if (order.color && order.content && order.imageList.length > 0) {
      try {
        setLoading(true);
        const result = await saveMulti(order);
        setLoading(false);

        if (result.success && result.count === order.imageList.length) {
          navigate(`/products`);
          toast.success("Siparişler başarıyla oluşturuldu.");
        }
        else {
          toast.error((order.imageList.length - result.count) + " adet siparişte hata gerçekleşti. Detaylar için konsol'a bakın");
          console.log(result.errorList)
        }

      } catch (e) {
        toast.error("Siparişler oluşturulurken hata oluştu: ", e);
      }
    } else {
      toast.error("Lütfen tüm alanları doldurun.");
    }
  };

  const uploadFile = () => {
    if (files.length === 0) {
      toast.error("Lütfen görselleri seçin.");
    }
    else {
      setLoading(true);
      setIsUploaded(true);
      setPercent(0);
      setMultiPercent(0);
      setUploadedFiles(0);
      uploadMultiImage(files, setPercent, setMultiUrl);
    }

  };

  const setMultiUrl = (val) => {
    setUploadedFiles(prevState => prevState + 1);
    setOrder(prevState => ({ ...prevState, imageList: [...prevState.imageList, val] }));
  };

  const onChangeOrder = (name, value) => {
    setOrder((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onRemoveImage = (index) => {
    const imageList = order.imageList;
    imageList.splice(index, 1);
    saveMulti({ ...order, imageList });
  };

  const compressorImages = (oldFiles) => {
    setIsUploaded(false);
    if (!oldFiles) {
      return;
    }
    let newFiles = [];
    for (let index = 0; index < oldFiles.length; index++) {
      setLoading(true);
      const file = oldFiles[index];
      new Compressor(file, {
        // Compression Settings
        quality: 0.6,
        maxWidth: 1000,
        success(result) {
          const outFile = new File([result], file.name, { type: result.type });
          newFiles.push(outFile);
          setFiles(newFiles);
          setLoading(false);
        },
        error(err) {
          console.log(err.message);
          setLoading(false);
        },
      });
    }


  };

  return (
    <div className="body-container">
      <div className="w-100 d-flex">
        <ButtonGroup
          className="ml-auto"
          variant="outlined"
          aria-label="outlined button group"
        >
          <ButtonMui disabled>Toplu Sipariş</ButtonMui>
          <ButtonMui
            onClick={() => {
              navigate(`/product-details/new`);
            }}
          >
            Tekli Sipariş
          </ButtonMui>
        </ButtonGroup>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item sm={6}>
          <Form>
            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Görseller:</h6>
              </Form.Label>

              <Col sm="9">
                <div>
                  <input
                    type="file"
                    onChange={(event) => {
                      compressorImages(event.target.files);
                    }}
                    accept="/image/*"
                    multiple
                  />
                  {files && files.length > 0 && (
                    <Box display="flex" marginTop="10px">
                      <Button
                        variant="success"
                        type="button"
                        onClick={uploadFile}
                        style={{ marginRight: "10px" }}
                        disabled={loading}
                      >
                        Yükle
                      </Button>
                      <Box width="100%">
                        <LinearProgressWithLabel value={percent} />
                        <LinearProgressWithLabel value={(uploadedFiles / files.length * 100)} />
                      </Box>
                    </Box>
                  )}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Ayar:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="18"
                  name="radio-content-group"
                  value={order.content}
                  onChange={(e) => {
                    onChangeOrder("content", e.target.value);
                  }}
                >
                  {filterData
                    .find((x) => x.name === "content")
                    ?.items.map((x) => {
                      return (
                        <FormControlLabel
                          value={x.value}
                          control={<Radio />}
                          label={x.name}
                          key={x.value}
                        />
                      );
                    })}
                </RadioGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Renk:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="gold"
                  name="radio-color-group"
                  value={order.color}
                  onChange={(e) => {
                    onChangeOrder("color", e.target.value);
                  }}
                >
                  {filterData
                    .find((x) => x.name === "color")
                    ?.items.map((x) => {
                      return (
                        <FormControlLabel
                          value={x.value}
                          control={<Radio />}
                          label={x.name}
                          key={x.value}
                        />
                      );
                    })}
                </RadioGroup>
              </Col>
            </Form.Group>
          </Form>
          <Box
            display="flex"
            justifyContent="end"
            paddingBottom="10px"
            paddingInline="40px"
          >
            <Button
              variant="primary"
              onClick={() => saveOrder()}
              disabled={!(files && files.length > 0 && isUploaded)}
            >
              Kaydet
            </Button>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <h6 style={{ marginLeft: '12px' }}>Toplam Görsel: {order.imageList.length}</h6>
          <Box
            style={{
              display: "grid", gridTemplateColumns: "auto auto auto", boxShadow: 'inset 0px 1px 11px 2px #c5c5c5',
              margin: '5px'
            }}
          >
            {order.imageList.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{ width: 150, height: 150, margin: "10px auto 10px auto", display: 'flex' }}
                >
                  <button
                    type="button"
                    style={{ position: "absolute", margin: "3px", zIndex: 100 }}
                    onClick={() => onRemoveImage(index)}
                  >
                    <Icon fontSize="inherit">delete</Icon>
                  </button>
                  <Box class="MuiCardMedia-root MuiCardMedia-media glass-magnifier-multi" sx={{
                    '& > div > img': {
                      height: "100%",
                      width: "100%",
                      margin: "auto"
                    }
                  }} >
                    <GlassMagnifier
                      imageSrc={item}
                      imageAlt="img_preview"
                      magnifierSize="30%"
                    />
                  </Box>
                </Card>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default MultiProducts;
