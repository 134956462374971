import React, {useContext} from "react";
import { UserContext } from "../../context";
import { Card, Box, Avatar, Stack, Typography, Divider, Chip } from "@mui/material";
import "./profile.css";

export default function Profile() {
    const [ user ] = useContext(UserContext);
  return (
    <div className="body-container">
      
      <Card>
        <Box sx={{ p: 2, display: "flex" }}>
          <Avatar variant="rounded"  sx={{m: 1}}/>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>{user.firstName} {user.lastName} <Chip label={user.email} sx={{marginLeft: 1}} /></Typography>
            <Divider />
            <Typography variant="body2" color="text.secondary">
               {user.email}
            </Typography>
          </Stack>
        </Box>
      </Card>
    </div>
  );
}
