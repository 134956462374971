import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./products.css";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Cards from "../../components/Cards";
import {
  Button, Grid, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, ButtonGroup,
  Icon, Pagination, Box
} from "@mui/material";
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import Filter from "../../components/Filter";
import "../../components/Cards/card.css";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { getList, getListByCustomerId } from "../../firebase/orderApi";
import { UserContext, OrdersContext, FiltersContext } from "../../context";
import MultiUpdate from "../ProductDetails/MultiUpdate";
import InfiniteScroll from 'react-infinite-scroller';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Products() {
  const [user] = useContext(UserContext);
  const [fetching, setFetching] = useState(false);
  const [orders, setOrders] = useContext(OrdersContext);
  const [selectedFilters, setSelectedFilters] = useContext(FiltersContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [viewMode, setViewMode] = useState("grid");
  const { id } = useParams();

  useEffect(() => {
    if (user)
      getOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user, selectedFilters]);


  const getOrderList = async () => {
    if (fetching) return;
    setFetching(true);
    const customerId = (id || user.role === "customer") && (id || user.uid);
    const orderResult = await getList(orders, selectedFilters, customerId);
    if (orderResult)
      setOrders(orderResult);
    setFetching(false);
  };


  const handleClose = (isSaved, isRemoved, order) => {
    setOpenDetailPopup(false);
    if (isSaved) {
      for (const [key, value] of Object.entries(order)) {
        if (value === '') delete order[key]
      }
      const updatedOrderList = orders.data.map(x => selectedItems.includes(x.id) ? { ...x, ...order } : x);
      setOrders({ ...orders, data: updatedOrderList, total: updatedOrderList.length });
      setSelectedItems([]);
    }
    else if (isRemoved) {
      const updatedOrderList = orders.data.filter(x => !selectedItems.includes(x.id));
      setOrders({ ...orders, data: updatedOrderList, total: updatedOrderList.length });
      setSelectedItems([]);
    }
  };

  return (
    <div className="body-container">
      <div className="d-flex searchBar">
        <Form.Control
          type="search"
          placeholder="Ara..."
          className="me-2 search"
          value={searchQuery}
          onChange={
            (e) => setSearchQuery(e.target.value)
          }
        />

        {user?.role === "admin" && selectedItems.length > 0 &&
          <Button variant="outlined" color="secondary"
            onClick={() => {
              if (selectedItems.length === orders.data.length)
                setSelectedItems([]);
              else
                setSelectedItems(orders.data.map(x => x.id))
            }}
            style={{
              marginLeft: 'auto',
              marginRight: '5px',
              fontSize: 'x-small',
              padding: '0px'
            }}
          >Tümünü Seç</Button>}

        {user?.role === "admin" && selectedItems.length > 0 &&
          <Button variant="outlined" color="success"
            onClick={() => setOpenDetailPopup(true)}
            style={{
              marginLeft: 'auto',
              marginRight: '5px',
              fontSize: 'x-small',
              padding: '0px'
            }}
          >Seçilenleri Tanımla</Button>}
        <ButtonGroup className="ml-auto" variant="outlined" aria-label="outlined button group">
          <Button disabled={viewMode === "grid"} onClick={() => {
            setViewMode("grid");
          }}><Icon>apps</Icon></Button>
          <Button disabled={viewMode === "single"} onClick={() => {
            setViewMode("single");
          }}><ViewStreamIcon /></Button>
        </ButtonGroup>
      </div>
      <div className="m-3">
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Filter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
          </Grid>
          <Grid item sm={9}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={fetching}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Row>
              {orders.data && orders.data.filter(x => x.customerName.toLowerCase().includes(searchQuery.toLowerCase()) || x.content.toLowerCase().includes(searchQuery.toLowerCase()) || x.color.toLowerCase().includes(searchQuery.toLowerCase())).map((order, idx) => (
                <Col sm={viewMode === "grid" ? "6" : "12"} lg={viewMode === "grid" ? "3" : "12"} key={idx}>
                  <Cards orderDetail={order} selectedItems={selectedItems} setSelectedItems={setSelectedItems} viewMode={viewMode} />
                </Col>
              ))}
            </Row>
            <Box display="flex" justifyContent="center">
              {orders.data && orders.data.length < orders.total && <Button variant="outlined" color="primary" onClick={() => getOrderList()} style={{ width: '50%', marginLeft: 'auto' }}>Daha Fazla Yükle</Button>}
              <ArrowCircleUpIcon fontSize="large" className="up" onClick={() => window.scrollTo(0, 0)} sx={{ cursor: "pointer" }} />
            </Box>


          </Grid>
        </Grid>
      </div>
      <MultiUpdate openDetailPopup={openDetailPopup} handleClose={handleClose} selectedItems={selectedItems} />

    </div>
  );
}

export default Products;
