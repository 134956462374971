import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid, Button, ButtonGroup, Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { toast } from 'react-toastify';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowIcon from "@mui/icons-material/Preview";
import { ListGroup } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomersContext } from "../../context";
import { getDayOrders } from '../../firebase/orderApi';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import "./metrics.css"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function OrderMetric() {
  const [loading, setLoading] = useState(false);
  const [dayOrders, setDayOrders] = useState([]);
  const dayCount = 30;

  useEffect(() => {
    getDayOrderList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDayOrderList = async (isForced) => {
    if (!(dayOrders && dayOrders.length > 0) || isForced) {
      setLoading(true);
      const dayList = await getDayOrders(dayCount);
      setDayOrders(dayList);
      setLoading(false);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${dayCount} Günlük Sipariş Durumu`,
      },
    },
  };


  let labels = [];

  for (let i = dayCount; i >= 0; i--) {
    var date = moment().subtract(i, 'day');
    labels.push(date.format('Do MMMM'));
  }


  const data = {
    labels,
    datasets: [
      {
        label: 'Tamamlanmamış Siparişler',
        data: dayOrders.map((order) => order.waitingOrderCount),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgb(255, 99, 132)'
      },
      {
        label: 'Tamamlanan Siparişler',
        data: dayOrders.map((order) => order.completedOrderCount),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgb(53, 162, 235)'
      },
      {
        label: 'Toplam Sipariş Sayısı',
        data: dayOrders.map((order) => order.totalOrderCount),
        backgroundColor: 'rgb(12, 129, 0, 0.5)',
        borderColor: 'rgb(12, 129, 0)'
      },
    ],
  };


  return (
    <Grid container spacing={2}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} >
        <Bar options={options} data={data} redraw />
      </Grid>
    </Grid>
  );
}

export default OrderMetric;
