import Layout from "components/Layout";
import React, {useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context";


const Authorization = ({ isPublic = false, userId, permissions }) => {

    const [user] = useContext(UserContext);
    const location = useLocation();
    
    if(isPublic){
        return <Layout isPublic={true} />
    }
    else if (userId || user?.uid) {
        const isAllowed = permissions.some((allowed) => user?.role===allowed);
        return <Layout isAllowed={isAllowed}  isPublic={false} />
    }
    else if (location.pathname !== "/login")
        return <Navigate to="/login" state={{ path: location.pathname }} replace />;

};
export default Authorization;