import React, {useState, useEffect, useContext} from 'react'
import { Button, Grid,  Dialog, Box,
    DialogActions,
    DialogContent,
    DialogTitle,  Card,
    CardActionArea,
    CardMedia,
    TextField,
    Autocomplete,
    Switch,
    RadioGroup,
    FormControlLabel,
    Radio } from "@mui/material";
    import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
    import Form from "react-bootstrap/Form";
    import { DatePicker } from '@mui/x-date-pickers/DatePicker';
    import { getCustomers } from './../../firebase/customerApi';
    import { updateMulti, removeMulti } from "../../firebase/orderApi";
    import { CustomersContext } from "../../context";
    import { toast } from 'react-toastify';

function MultiUpdate({openDetailPopup, handleClose, selectedItems }) {
    const [ customers, setCustomers ] = useContext(CustomersContext);
    const [order, setOrder] = useState({
        color: '',
        description: '',
        content: '',
        customerId: '',
        customerName: '',
        date: new Date().toDateString(),
        state: ''
      });

    useEffect(() => {
      if(openDetailPopup){
        getCustomerList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDetailPopup])

    const getCustomerList = async () => {
      if(!(customers && customers.length > 0)){
        const customerList = await getCustomers();
        setCustomers(customerList.map(customer => { return { uid: customer.uid, label: customer.firstName + " " + customer.lastName } }));
      }
    };
    
      const onChangeOrder = (name, value) => {
        setOrder(prevState => {
          return {
            ...prevState,
            [name]: value
          }
        })
      }

      const onDelete = async () => {
        try {
          await removeMulti(selectedItems);
          onClose(false, true);
          toast.success("Siparişler başarıyla silindi.");
        } catch (e) {
          toast.error("Siparişler silinirken hata oluştu: ", e);
        }
      }

      const onSave = async () => {
        try {
          await updateMulti(selectedItems, order);
          onClose(true, false);
          toast.success("Siparişler başarıyla güncellendi.");
        } catch (e) {
          toast.error("Siparişler güncellenirken hata oluştu: ", e);
        }
      }


    const onClose = (isSaved, isRemoved) => {
          handleClose(isSaved, isRemoved, order);
          setOrder({
            color: '',
            description: '',
            content: '',
            customerId: '',
            customerName: '',
            date: new Date().toDateString(),
            state: ''
          });
    }

  return (
    <Dialog open={openDetailPopup} onClose={onClose}>
    <DialogTitle>Seçilen siparişler: {selectedItems && selectedItems.length}</DialogTitle>
    <DialogContent>
    <Box>
          <Form>
            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label column sm="3">
                <h6>Müşteri Adı:</h6>
              </Form.Label>
              <Col sm="5">
                <Autocomplete
                  id="combo-box-demo"
                  options={customers}
                  value={order.customerName}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Müşteri Seçiniz" />
                  )}
                  onChange={(e, val) => {
                    setOrder(prevState => {
                      return {
                        ...prevState,
                        customerId: val ? val.uid : '',
                        customerName: val ? val.label : ''
                      }
                    })
                  }}
                />
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label column sm="3">
                <h6>Açıklama:</h6>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Açıklama giriniz."
                  value={order.description}
                  onChange={e => {
                    onChangeOrder("description", e.target.value)
                  }}
                />
              </Col>
            </Form.Group> */}

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Ayar:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="18"
                  name="radio-content-group"

                  value={order.content}
                  onChange={e => {
                    onChangeOrder("content", e.target.value)
                  }}
                >
                  <FormControlLabel value="18" control={<Radio />} label="18 Ayar" />
                  <FormControlLabel value="20" control={<Radio />} label="20 Ayar" />
                  <FormControlLabel value="22" control={<Radio />} label="22 Ayar" />
                </RadioGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Renk:</h6>
              </Form.Label>

              <Col sm="9">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="gold"
                  name="radio-color-group"

                  value={order.color}
                  onChange={e => {
                    onChangeOrder("color", e.target.value)
                  }}
                >
                  <FormControlLabel value="gold" control={<Radio />} label="Gold" />
                  <FormControlLabel value="silver" control={<Radio />} label="Silver" />
                  <FormControlLabel value="rose" control={<Radio />} label="Rose" />
                  <FormControlLabel value="other" control={<Radio />} label="Diğer" />
                </RadioGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Tarih:</h6>
              </Form.Label>

              <Col sm="9">
                <DatePicker
                  value={new Date(order.date)}
                  onChange={e => {
                    onChangeOrder("date", new Date(e).toDateString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="m-3" controlId="formPlainText">
              <Form.Label id="demo-form-control-label-placement" column sm="3">
                <h6> Tamamlanma Durumu:</h6>
              </Form.Label>

              <Col sm="9">

                <Switch checked={order.state}
                  onChange={e => {
                    onChangeOrder("state", e.target.checked)
                  }}
                />

              </Col>
            </Form.Group>
          </Form>

    </Box>
      <span style={{fontSize: 'smaller'}}>*Seçilen siparişler, sadece doldurulan bilgilerle güncellenecektir.</span>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={onClose}>Vazgeç</Button>
      <Button color="error" onClick={onDelete}>Seçilenleri Sil</Button>
      <Button color="primary" onClick={onSave}>Güncelle</Button>
    </DialogActions>
  </Dialog>

  )
}

export default MultiUpdate