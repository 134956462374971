import { useNavigate } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import {
  Card,
  CardActionArea,
  CardMedia,
  TextField,
  Autocomplete,
  Grid,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  ButtonGroup,
  Box,
  Button,
} from "@mui/material";

function LeavingNotice({ leavingState, setLeavingState, onSaveAndLeave }) {
  const navigate = useNavigate();

  return (
    <ReactRouterPrompt when={leavingState}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog
          width="500"
          height="150"
          open={isActive}
          showCloseButton={false}
          closeOnOutsideClick={false}
        >
          <DialogTitle>Kaydedilmemiş Değişiklikler</DialogTitle>
          <DialogContent>
            <div className="h-full">
              <span style={{ fontSize: "16px" }}>
                Kaydedilmemiş değişiklikleriniz var. Ayrılmadan önce kaydetmek
                ister misiniz?
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              className="mt-10"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                color="inherit"
                className="mx-1"
                onClick={() => {
                  onCancel();
                }}
              >
                Kapat
              </Button>
              <Button
                variant="contained"
                color="error"
                className="mx-1"
                onClick={() => {
                  onConfirm();
                }}
              >
                Kaydetme & Ayrıl
              </Button>
              <Button
                variant="contained"
                color="success"
                className="mx-1"
                onClick={() => {
                  onSaveAndLeave();
                  onConfirm();

                }}
              >
                Kaydet & Ayrıl
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
}

export default LeavingNotice;
