import React, {useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { UserContext } from "../../context";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase/config"
import logo from "../../assets/images/logo.png"
import banner from "../../assets/images/banner.png"
import "./header.css";
import { useLocation } from 'react-router-dom';



function Header({isPublic}) {
  const [ user, setUser ] = useContext(UserContext);
  const { pathname, hash } = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setUser(null);
    signOut(auth);
  }

  const renderLink = (path, name) => {
    const className = `nav-link scrollto ${(pathname+hash) === path ? "active" : ""}`;
    return <li><a className={className} href={path}>{name}</a></li>;
  }

  return (
    <header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center justify-content-lg-between">

      <a href="/" className="logo me-auto me-lg-0"><img src={logo} alt="site-logo" className="img-fluid logo-icon" /> <img src={banner} alt="site-logo" className="img-fluid logo-title" /></a>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          {renderLink("/","Anasayfa")}
          {!user?.uid && <>
            {renderLink("/#about","Hakkımızda")}
            {renderLink("/#services","Hizmetlerimiz")}
            {renderLink("/#portfolio","Portfolyo")}
            {renderLink("/#contact","İletişim")}
          </>}
          {user?.uid && renderLink("/products", user?.role==="admin" ? "Siparişler" : "Siparişlerim")}
          {user?.role==="admin" && <>
          {renderLink("/product-details/multi","Sipariş Oluştur")}
          {/* {renderLink("/product-details/new","Yeni Sipariş")} */}
          {renderLink("/customers","Müşteriler")}
          {renderLink("/metrics","Metrikler")}
          {renderLink("/file-manager","Dosya Yönetimi")}
          </>}
          {user?.role==="customer" && renderLink("/profile","Profilim")}
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
      {user?.uid ? <button className="get-started-btn scrollto" onClick={()=>logout()}>Çıkış Yap</button> : <a className="get-started-btn scrollto" href="/login">Giriş Yap</a>}
    </div>
  </header>
  )
}

export default Header

   