import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid, Button, ButtonGroup, Tooltip, Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { toast } from 'react-toastify';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowIcon from "@mui/icons-material/Preview";
import { ListGroup } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomerDetail from './CustomerDetail';
import { CustomersContext } from "../../context";
import { createCustomer, getCustomers, updateCustomer, deleteCustomer } from './../../firebase/customerApi';
import Visibility from "@mui/icons-material/Visibility";
import "./customers.css"

function Customers() {
  const customerInit = { uid: '', firstName: '', lastName: '', email: '', phone: '' };
  const [loading, setLoading] = useState(false);
  const [displayOrderCounts, setDisplayOrderCounts] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [customer, setCustomer] = useState(customerInit);
  const [customers, setCustomers] = useContext(CustomersContext);
  const [sortBy, setSortBy] = useState('firstName');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayOrderCounts])


  const getCustomerList = async (isForced) => {
    if (!(customers && customers.length > 0) || isForced || (displayOrderCounts && !Number.isFinite(customers[0].totalOrderCount))) {
      setLoading(true);
      const customerList = await getCustomers(displayOrderCounts);
      setCustomers(customerList);
      setLoading(false);
    }
  };


  const handleCreateCustomer = () => {
    setCustomer(customerInit)
    setOpenDetailPopup(true);
  };

  const handleClose = () => {
    setOpenDetailPopup(false);
    setOpenDeletePopup(false);
  };

  const handleSave = async () => {
    let isSuccess = false;
    if (customer.uid)
      isSuccess = await updateCustomer(customer);
    else
      isSuccess = await createCustomer(customer);

    if (isSuccess) {
      toast.success("Müşteri kaydedildi.");
      handleClose();
      getCustomerList(true);
    }
    else {
      toast.error("Müşteri kaydedilemedi.");
    }

  }

  const handleDelete = async () => {
    let isSuccess = deleteCustomer(customer.uid);
    if (isSuccess) {
      toast.success("Müşteri başarıyla silindi.");
      handleClose();
      setCustomers(customers.filter(item => item.uid !== customer.uid));
    }
    else {
      toast.error("Müşteri silinemedi.");
    }
  }

  const sortCustomers = (list, sortBy, sortDirection) => {

    let sortedList = [...list];

    if (sortBy === 'firstName') {
      sortedList = sortedList.sort((a, b) => {
        if (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase()) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase()) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    else {
      sortedList = sortedList.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortedList;
  }

  const titleRender = (title, name) => {
    return <div className="cursor-pointer" style={{ display: 'flex', justifyContent: name.includes('Count') ? 'center' : 'unset' }} onClick={() => {
      setSortDirection(sortDirection === "asc" ? 'desc' : "asc");
      setSortBy(name);
    }}>
      <b>{title}</b>
      {sortBy === name && <div>
        {sortDirection === "asc" ? 
        <KeyboardArrowDownIcon /> : 
        <KeyboardArrowUpIcon />}
      </div>}
    </div>

  }

  return (
    <div className="body-container">
      <h2 style={{ textAlign: 'center' }}>Müşteriler</h2>
      <Box display="flex" justifyContent="end" marginBottom={2}>
      <Button variant="contained" color="success" onClick={()=>setDisplayOrderCounts(!displayOrderCounts)} style={{marginRight: '8px'}}><Visibility /> Sipariş Sayılarını {displayOrderCounts?'Gizle':'Göster'}</Button>
        <Button variant="contained" color="success" onClick={handleCreateCustomer}><AddIcon /> Yeni Müşteri</Button>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Grid container style={{ margin: '10px' }}>
          <Grid item sm={2}>{titleRender("Ad Soyad", "firstName")}</Grid>
          <Grid item sm={displayOrderCounts ? 2 : 8}>{titleRender("E-Mail", "email")}</Grid>
          {displayOrderCounts && <>
            <Grid item sm={2} textAlign="center">{titleRender("Tamamlanmayan Sipariş", "waitingOrderCount")}</Grid>
          <Grid item sm={2} textAlign="center">{titleRender("Tamamlanan Sipariş", "completedOrderCount")}</Grid>
          <Grid item sm={2} textAlign="center">{titleRender("Toplam Sipariş", "totalOrderCount")}</Grid>
          </>}
          <Grid item sm={2} textAlign="center"><b>İşlemler</b></Grid>
        </Grid>
        <ListGroup variant="secondary">
          {
            sortCustomers((customers || []), sortBy, sortDirection).map(item => {
              return <ListGroup.Item
                key={item.uid}
                variant="secondary"
                className="d-flex justify-content-between align-items-start"
                style={{ backgroundColor: '#f3f3f3!important' }}
              >
                <Grid container>
                  <Grid item sm={2}>{item.firstName} {item.lastName}</Grid>
                  <Grid item sm={displayOrderCounts ? 2 : 8}>{item.email}</Grid>
                  {displayOrderCounts &&
                  <>
                  <Grid item sm={2} textAlign="center">{item.waitingOrderCount??0}</Grid>
                  <Grid item sm={2} textAlign="center">{item.totalOrderCount??0 - item.waitingOrderCount??0}</Grid>
                  <Grid item sm={2} textAlign="center">{item.totalOrderCount??0}</Grid>
                  </>}
                  <Grid item sm={2} textAlign="center">
                    <ButtonGroup style={{ marginLeft: '30px' }}>
                      <Button size="small" variant="outlined" color="primary" onClick={() => navigate(`/products/${item.uid}`)}><Tooltip title="Siparişleri Gör"><ShowIcon /></Tooltip></Button>
                      <Button size="small" variant="outlined" color="warning" onClick={() => { setCustomer(item); setOpenDetailPopup(true); }}><Tooltip title="Düzenle"><EditIcon /></Tooltip></Button>
                      <Button size="small" variant="outlined" color="error" onClick={() => { setCustomer(item); setOpenDeletePopup(true); }}><Tooltip title="Sil"><DeleteIcon /></Tooltip></Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </ListGroup.Item>
            })
          }

        </ListGroup>
      </Box>


      <Dialog open={openDetailPopup} onClose={handleClose}>
        <DialogTitle>Müşteri Detayları</DialogTitle>
        <DialogContent>
          <CustomerDetail customer={customer} setCustomer={setCustomer} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button onClick={handleSave}>Kaydet</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeletePopup} onClose={handleClose}>
        <DialogContent>
          <p>Seçilen müşteriyi silmek istediğinize emin misiniz? Bu geri alınamayan bir işlemdir.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button color="error" onClick={handleDelete}>Sil</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default Customers;
