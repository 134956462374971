import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes
} from "react-router-dom";
import Authorization from './pages/Auth/Authorization';
import Login from './pages/Auth/Login';
import Home from './pages/Home';
import Customers from './pages/Customers';
import Products from './pages/Products';
import ProductDetails from 'pages/ProductDetails';
import MultiProducts from 'pages/ProductDetails/MultiProducts';
import Profile from 'pages/Profile';
import Metrics from 'pages/Metrics';
import { UserContext, CustomersContext, OrdersContext, FiltersContext } from 'context';
import { getCustomerById } from "./firebase/customerApi";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import './assets/style/custom.css';
import './assets/style/main.css';


const FileManager = React.lazy(() => import("pages/FileManager"));

function App() {

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [user, setUser] = useState({uid: userId, role: token && (token[0] === "a" ? "admin" : "customer")});

  useEffect(() => {

    if (token && userId && !user?.role) {
      getCurrentUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [customers, setCustomers] = useState([]);
  const [orders, setOrders] = useState({data:[], lastDoc: null, total: 0});
  const [selectedFilters, setSelectedFilters] = useState({date: moment(), customer: []});

  const getCurrentUser = async (userId) => {
    const customer = await getCustomerById(userId);
    setUser(customer)
  }
  
  const router = createBrowserRouter(
    createRoutesFromElements(<>
      <Route element={<Authorization isPublic={true} />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<Authorization userId={userId} permissions={["admin"]} isPublic={false} />}>
        <Route path="/customers" element={<Customers />} />
        <Route path="/metrics" element={<Metrics />} />
        <Route path="/file-manager" element={<FileManager />} />
        <Route path="/product-details/multi" element={<MultiProducts />} />
      </Route>
      <Route element={<Authorization userId={userId} permissions={["admin", "customer"]} isPublic={false} />}>
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
      </>
    )
  );

  return (

    <UserContext.Provider value={[ user, setUser ]}>
      <CustomersContext.Provider value={[customers, setCustomers]}>
        <OrdersContext.Provider value={[orders, setOrders]}>
          <FiltersContext.Provider value={[selectedFilters, setSelectedFilters]}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
              <RouterProvider router={router} />
          </LocalizationProvider>
          </FiltersContext.Provider>
        </OrdersContext.Provider>
      </CustomersContext.Provider>
    </UserContext.Provider>


  );
}



export default App;

