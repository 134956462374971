import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className="footer"><span>&copy;2023 Zirve Döküm. All rights reserved.</span> <span style={{
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    }}>Powered by <a href="http://www.asnus.com"><img src="https://asnus.com/images/logo.svg" height="25px" alt="asnus" style={{marginBottom: '3px'}}/></a></span></div>
  )
}

export default Footer