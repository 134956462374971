import React from 'react';
import banner from "../../assets/images/banner.png";
import "./home.css"

function Home() {

  const pageType = 'old';

  // eslint-disable-next-line no-use-before-define
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/63fc89994247f20fefe2df18/1gq97j02s';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();

  // if (pageType === 'old')
  //   return (
  //     <div className='body'>
  //       <div style={{
  //         height: '50px',
  //         width: '100%',
  //         backgroundColor: 'white',
  //         position: 'absolute',
  //         left: 0,
  //         top: 0
  //       }} />
  //       <iframe src="https://zirvegold.business.site" width="100%" height="3600px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style={{ marginTop: '-115px' }} title="zirvegold-google" />
  //     </div>
  //   );

  return (
    <>
      <section id="hero" className="d-flex align-items-center justify-content-center">
        <div className="container" data-aos="fade-up">

          <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
            <div className="col-xl-6 col-lg-8">
              <div className="hero-logo"><img src="assets/img/logo.png" className="img-fluid" alt="" /></div>
              <h1 className="hero-title">Değerli Maden Döküm Hizmetleri</h1>
              <h2>Sizin başarınız, bizim başarımız.</h2>
            </div>
          </div>

          <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <h3>Özel Takı İmalatı</h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <h3>Müşterilerin Seveceği Ürünler</h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <h3>Kaliteye Bağlılık</h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <h3>Son Teknoloji</h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <h3>İhtiyacınız Olan Herşey Tek Bir Yerde</h3>
              </div>
            </div>
          </div>

        </div>
      </section>

      <main id="main">

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">

            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <img src="assets/img/1.png" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 p-5 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <h3 style={{ marginTop: '50px', marginBottom: '20px' }}>Daha Yakından Bir Bakış.</h3>
                <p>
                  İster bir nişan yüzüğü, ister alyans, ister sevdiğiniz bir mücevher parçasının reprodüksiyonu, ister bir müşteriniz için özelleştirilmiş bir parça, ister kuyumcu dükkanınız için yeni bir parça yaratıyor olun, altın genellikle tercih edilen metaldir. Zirve Döküm tarafından sunulan maden döküm takı hizmetleri, yüksek kaliteli takı parçaları yaratmanıza olanak tanır.
                </p>
                <p>
                  Altın döküm, mücevher yaratma sürecinin bir parçasıdır. Tasarımcıların ve kuyumcuların bir fikri veya tasarımı almasına, bir kalıp oluşturmasına ve sonunda nihai ürünün fiziksel bir reprodüksiyonunu elde etmesine olanak tanır.
                </p>
              </div>
            </div>

          </div>
        </section>
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">

            <div className="row">
              <div className="image col-lg-6" style={{ backgroundImage: 'url("assets/img/2.png")' }} data-aos="fade-right"></div>
              <div className="col-lg-6 p-5 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <p style={{ marginTop: '50px' }}>
                  Altın döküm işlemi aşağıdaki adımları içerir:
                </p>
                <ul>
                  <li><i className="ri-check-double-line"></i> <b>Balmumu modeli ve döküm hazırlığı.</b> Tasarım kullanılarak, bitmiş parçanın üç boyutlu olarak nasıl görüneceğini temsil etmek için bir balmumu modeli oyulur veya basılır. Bunlar bir yolluk ile bir balmumu gövdeye bağlanır. Bu, kalıp oluşturulduktan sonra erimiş altının içinden akacağı kanalı oluşturacaktır.</li>
                  <li><i className="ri-check-double-line"></i> <b>Yatırım.</b> Balmumu sap ve bağlı modeller şişe adı verilen özel bir çerçeveye yerleştirilir ve bir yatırım malzemesi ile çevrelenir. Yatırım malzemesi sertleştikten sonra takının kalıbına dönüşür.</li>
                  <li><i className="ri-check-double-line"></i> <b>Tükenmişlik.</b> Yatırım malzemesi sertleştikten sonra, balmumunu yakmak için şişe özel bir fırına yerleştirilir, bu da yatırım malzemesinde nihai kalıp haline gelen bir boşluk bırakır.</li>
                  <li><i className="ri-check-double-line"></i> <b>Eritme.</b> Altın eritilir ve kullanılan altın alaşımına veya türüne bağlı olarak sıcaklıklar 1.617 Fahrenheit ile 2.006 Fahrenheit derece arasında değişebilir. Altın, gümüş, bakır ve paladyum gibi diğer metallerle birleştirilerek farklı renk ve sertlikte alaşımlar oluşturulabilir.</li>
                  <li><i className="ri-check-double-line"></i> <b>Döküm.</b> Sıvı altın doğrudan kalıba dökülebilir, ancak daha kaliteli bir döküm sağlamak için çeşitli teknikler kullanılabilir. Altının kalıbın her alanına eşit şekilde akmasını sağlamak için santrifüj döküm, vakum destekli döküm veya basınçlı döküm kullanılabilir.</li>
                  <li><i className="ri-check-double-line"></i> <b>Boşaltma.</b> Metal soğuduktan sonra yatırım malzemesinden çıkarılması gerekir. Bu fiziksel veya kimyasal yollarla yapılabilir. Her döküm temizlenir ve parlatılır ve yolluklar geri dönüşüm için kesilir.</li>
                </ul>
              </div>
            </div>

          </div>
        </section>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Hizmetlerimiz</h2>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bx bxl-dribbble"></i></div>
                  <h4>Bilgisayar Destekli Üretim</h4>
                  <p>Bilgisayar teknolojisinin kullanılarak üretim süreçlerinin tasarlanması, simülasyonu ve kontrol edilmesi</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-file"></i></div>
                  <h4>Özel Yapım Takılar</h4>
                  <p>Kişisel zevklere ve ihtiyaçlara göre tasarlanmış ve üretilmiş takı parçaları</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-tachometer"></i></div>
                  <h4>Kalıp Yapımı</h4>
                  <p>Bir ürünün seri üretim için kullanılacak kalıplarının tasarlanması ve üretilmesi süreci</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-world"></i></div>
                  <h4>Balmumu Dökümü</h4>
                  <p>Kayıp balmumu tekniği olarak da bilinen bir döküm yöntemi. Bu yöntemde, öncelikle istenilen parçanın balmumu formu oluşturulması ve döküm hazırlığı için kullanılması</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-slideshow"></i></div>
                  <h4>Pırlanta/Taş Ayarı</h4>
                  <p>Bu süreçte, pırlanta veya diğer taşlar, takıdaki metal ayarına yerleştirilir. Usta, taşı güvenli ve estetik bir şekilde takının tasarımına entegre eder. Bu işlemde taşın güvenliği, doğru pozisyonu ve takının genel estetiği göz önünde bulundurulur</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-arch"></i></div>
                  <h4>Gravür</h4>
                  <p>Takı yüzeyinin oyma veya kazıma yoluyla süslenmesi</p>
                </div>
              </div>

            </div>

          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">

            <div className="text-center">
              <h3>İletişime Geç</h3>
              <p> Hizmetlerimizden faydalanmak için hemen bizimle irtibata geçin.</p>
              <a className="cta-btn" href="/#contact">İletişime Geç</a>
            </div>

          </div>
        </section>
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Portfolyomuz</h2>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">Tümü</li>
                  <li data-filter=".filter-app">Kolye</li>
                  <li data-filter=".filter-card">Bilezik</li>
                  <li data-filter=".filter-web">Yüzük</li>
                </ul>
              </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/yuzuk-1.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>App 1</h4>
                    <p>Yüzük</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/yuzuk-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/bilezik-1.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Web 3</h4>
                    <p>Bilezik</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/bilezik-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/yuzuk-2.png" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>App 2</h4>
                    <p>Yüzük</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/yuzuk-2.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 2"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/kolye-1.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Card 2</h4>
                    <p>Kolye</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/kolye-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/bilezik-2.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Web 2</h4>
                    <p>Bilezik</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/bilezik-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 2"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/yuzuk-3.png" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>App 3</h4>
                    <p>Yüzük</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/yuzuk-3.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 3"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/kolye-2.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Card 1</h4>
                    <p>Kolye</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/kolye-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 1"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/kolye-3.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Card 3</h4>
                    <p>Kolye</p>
                    <div className="portfolio-links">
                      <a href="assets/img/portfolio/kolye-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 3"><i className="bx bx-plus"></i></a>
                      <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container" data-aos="zoom-in">

            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img src="https://ei7cr2pn3uw.exactdn.com/wp-content/uploads/2021/01/George-Herbert-Portrait.jpg?strip=all&lossy=1&ssl=1" className="testimonial-img" alt="" />
                    <h3>George Herbert</h3>
                    <h4>Britanyalı Şair</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Altın tüm kilitleri açar, altının gücüne karşı kilit tutmaz.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section>

        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">

            <div className="row no-gutters">
              <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay="100"></div>
              <div className="col-xl-7 ps-4 ps-lg-5 pe-4 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay="100">
                <div className="content d-flex flex-column justify-content-center">
                  <h3>Hizmet anlayışımız</h3>
                  <p>
                    Müşterilerimizin maden döküm hizmetleri için Zirve Döküm'ü seçmelerinin nedenlerinden bazıları:
                  </p>
                  <div className="row">
                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-emoji-smile"></i>
                        <span data-purecounter-start="0" data-purecounter-end="65" data-purecounter-duration="2" className="purecounter"></span>
                        <p><strong>Güven</strong> <br />Çok aşamalı bir kalite güvence sürecinin kullanılması.</p>
                      </div>
                    </div>

                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-journal-richtext"></i>
                        <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="2" className="purecounter"></span>
                        <p><strong>Hız</strong> <br /> Döküm siparişlerinde hızlı geri dönüş, kolay teslim süreci ve toplu sipariş verebilme</p>
                      </div>
                    </div>

                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-clock"></i>
                        <span data-purecounter-start="0" data-purecounter-end="35" data-purecounter-duration="4" className="purecounter"></span>
                        <p><strong>Yılların Deneyimi</strong> <br /> Sizinle birlikte çalışacak ve olağanüstü bir ürün elde etmek için ihtiyacınız olan hizmetlerle sizi eşleştirecek bir ekip </p>
                      </div>
                    </div>

                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-award"></i>
                        <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="4" className="purecounter"></span>
                        <p><strong>Kalite</strong> <br /> Yüksek kaliteli fotopolimer teknolojileri ve son teknoloji kalıplama kullanımı</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>İletişim</h2>
            </div>

            <div>
              <iframe style={{ border: 0, width: '100%', height: '270px' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12042.589207289104!2d28.9724887!3d41.0110923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9e6ef95b27f%3A0x76356c32c98c38c2!2zWmlydmUgRMO2a8O8bQ!5e0!3m2!1str!2str!4v1702553151857!5m2!1str!2str" frameborder="0" allowfullscreen title="map"></iframe>
            </div>

            <div className="row mt-5">

              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Konum:</h4>
                    <p>Mollafenari Mh. Adem Yavuz Sk. No: 18 Kat:3 <br /> Yenice İşhanı Fatih/İstanbul</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>E-Posta:</h4>
                    <p>info@zirvedokum.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Telefon:</h4>
                    <p>0505 874 02 94</p>
                  </div>

                </div>

              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">

                <form action="https://formbold.com/s/ozeNl" method="post" className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="İsminiz" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="E-Mail Adresiniz" required />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Konu" required />
                  </div>
                  <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Mesajınız" required></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Yükleniyor</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Mesajınız iletildi. Teşekkürler!</div>
                  </div>
                  <div className="text-center"><button type="submit">Mesajı İlet</button></div>
                </form>

              </div>

            </div>

          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">

              <div className="col-lg-8 col-md-6">
                <div className="footer-info">
                  <img src={banner} alt="site-logo" style={{ height: '16px', marginBottom: '12px' }} />
                  <p>
                    Mollafenari Mh. Adem Yavuz Sk.<br />
                    No: 18 Kat:3 Yenice İşhanı<br />
                    34110 Fatih/İstanbul<br /><br />
                    <strong>Telefon:</strong> 0505 874 02 94<br />
                    <strong>E-Posta:</strong> info@zirvedokum.com<br />
                  </p>
                </div>
                <div className="social-links mt-3">
                  <a href="https://www.instagram.com/zirve_dokum/" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <span className="facebook"><i className="bx bxl-facebook"></i></span>
                  <span className="twitter"><i className="bx bxl-twitter"></i></span>
                  <span className="linkedin"><i className="bx bxl-linkedin"></i></span>
                </div>
              </div>


              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Çalışma Saatleri</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> Haftaiçi:	09:00–19:00</li>
                  <li><i className="bx bx-chevron-right"></i> Cmt:	09:00–15:00</li>
                  <li><i className="bx bx-chevron-right"></i> Paz:	Kapalı</li>
                </ul>
              </div>

            </div>
          </div>
        </div>

      </footer>
      {/* <div id="preloader" /> */}
    </>
  )
}



export default Home;
