import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Grid, Button, ButtonGroup, Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, RadioGroup,
    FormControlLabel, TextField,
    Radio,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { toast } from 'react-toastify';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowIcon from "@mui/icons-material/Preview";
import { ListGroup } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomersContext } from "../../context";
import { createCustomer, getCustomers, updateCustomer, deleteCustomer } from '../../firebase/customerApi';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import "./metrics.css"
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function CustomerMetric() {
    const today = moment().toDate().toDateString();
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useContext(CustomersContext);
    const [metricType, setMetricType] = useState("daily");
    const [date, setDate] = useState(today);

    useEffect(() => {
        getCustomerList(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    const sortCustomers = (list, sortBy, sortDirection) => {

        let sortedList = [...list];

        sortedList = sortedList.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });

        return sortedList;
    }


    const getCustomerList = async (isForced) => {
        if (!(customers && customers.length > 0 && Number.isFinite(customers[0].totalOrderCount)) || isForced) {
            setLoading(true);
            const customerList = await getCustomers(true, date);
            setCustomers(sortCustomers(customerList, 'totalOrderCount', 'desc'));
            setLoading(false);
        }
    };


    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Müşteri Sipariş Durumu',
            },
        },
    };

    const data = {
        labels: customers.map((customer) => `${customer.firstName} ${customer.lastName}`),
        datasets: [
            {
                label: 'Tamamlanmamış Siparişler',
                data: customers.map((customer) => customer.waitingOrderCount),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgb(255, 99, 132)'
            },
            {
                label: 'Tamamlanan Siparişler',
                data: customers.map((customer) => customer.completedOrderCount),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'rgb(53, 162, 235)'
            },
            {
                label: 'Toplam Sipariş Sayısı',
                data: customers.map((customer) => customer.totalOrderCount),
                backgroundColor: 'rgb(12, 129, 0, 0.5)',
                borderColor: 'rgb(12, 129, 0)'
            },
        ],
    };



    return (
        <Grid container spacing={2}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={6}>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={metricType}
                    onChange={(e) => {
                        if (e.target.value === "all") {
                            setDate(null);
                        }
                        else {
                            setDate(today);
                        }
                        setMetricType(e.target.value);
                    }}
                    style={{ height: '56px' }}
                >
                    <FormControlLabel value="all" control={<Radio />} label="Tüm Zamanlar" />
                    <FormControlLabel value="daily" control={<Radio />} label="Günlük" />
                </RadioGroup>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', justifyContent: 'end'}}>
                {metricType === "daily" && <DatePicker
                    value={moment(date).toDate()}
                    onChange={(val) => setDate(moment(val).toDate().toDateString())}
                    renderInput={(params) => <TextField {...params} />}
                />}
            </Grid>
            <Grid item xs={12} style={{ height: customers.length * 40 }}>
                <Bar options={options} data={data} />
            </Grid>
        </Grid>
    );
}

export default CustomerMetric;
